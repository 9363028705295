import React from "react"

function SearchPagination({ currentRefinement, nbPages, createURL, pageNumber }) {
  return (
    <div className="pagination" aria-label="Pagination">
      <ul>
        {new Array(nbPages).fill(null).map((_, index) => {
          const page = index + 1;
          if (pageNumber === page) {
            return (
              <li className="active" key={index}>
                <a href={createURL(page)}>{page}</a>
              </li>
            );
          }
          else {
            return (
              <li key={index}>
                <a href={createURL(page)}>{page}</a>
              </li>
            );
          }
        })}
      </ul>
    </div>
  )
}

export default SearchPagination