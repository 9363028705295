import React from "react"
import { useSiteFooter } from "../../hooks/useSiteFooter"
import { useSiteHeader } from "../../hooks/useSiteHeader"
import DynamicComponent from "../dynamicComponent"
import DynamicGlobalComponent from "../dynamicGlobalComponent"
import ScrollToTop from "../internal/ScrollToTop"

import Breadcrumb from "../internal/Breadcrumb"

function StandardPageLayout({pagePath, pagination, mainContent, newsStories, children}) {
  const header = useSiteHeader(),
        footer = useSiteFooter()
  let headComponents = [],
      mainComponents = [],
      footComponents = []
  // Header
  if(header && header.body) {
    for(let prop in header.body) {
      const blok = header.body[prop]
      headComponents.push(<DynamicGlobalComponent blok={blok} disableEdit={true} key={blok["_uid"]} />)
    }
  }
  // Breadcrumb
  if(mainContent && mainContent.show_breadcrumb) {
    headComponents.push(<Breadcrumb pagePath={pagePath} key="page_breadcrumb" />)
  }
  if(mainContent) {
    // Body
    for(let prop in mainContent.body) {
      const blok = mainContent.body[prop]
      let data = {}
      if(newsStories) {
        data.newsStories = newsStories
      }
      if(pagination) {
        data.pagination = pagination
      }
      mainComponents.push(<DynamicComponent blok={blok} key={blok["_uid"]} data={data} />)
    }
  }
  if(footer && footer.body) {
    // Footer
    for(let prop in footer.body) {
      const blok = footer.body[prop]
      footComponents.push(<DynamicGlobalComponent disableEdit={true} blok={blok} key={blok["_uid"]} />)
    }
  }

  return (
    <div className="body-wrapper">
      {headComponents}
      {mainComponents}
      {children}
      {footComponents}
      <ScrollToTop />
    </div>
  )
}

export default StandardPageLayout