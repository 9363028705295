import React, { useEffect, useState } from "react"
import qs from "qs"
import algoliasearch from "algoliasearch/lite"
import { Helmet } from "react-helmet"
import { InstantSearch, Hits, connectSearchBox, connectPagination, } from "react-instantsearch-dom"

import StandardPageLayout from "../components/layout/StandardPageLayout"
import SearchBox from "../components/internal/SearchBox"
import SearchHit from "../components/internal/SearchHit"
import SearchPagination from "../components/internal/SearchPagination"
import Breadcrumb from "../components/internal/Breadcrumb"

const DEBOUNCE_TIME = 400

const createURL = state => `?${qs.stringify(state)}`
const searchStateToUrl = ({ location }, searchState) => searchState ? `${location.pathname.split('?')[0]}${createURL(searchState)}` : '';
const urlToSearchState = ({ search }) => qs.parse(search.slice(1));

const CustomSearchBox = connectSearchBox(SearchBox)
const CustomPagination = connectPagination(SearchPagination)


function SearchPage({ location, history }) {
  // State Declarations
  const [searchQueryDisplay, setSearchQueryDisplay] = useState()
  const [lastResultIndex, setLastResultIndex] = useState()
  const [numberOfRecords, setNumberOfRecords] = useState()
  const [currentPage, setCurrentPage] = useState()
  const [searchState, setSearchState] = useState(urlToSearchState(location))
  const [debouncedSetState, setDebouncedSetState] = useState(null)

  // Init algolia
  const searchClient = algoliasearch(
    "AA4VQOOOVT",
    "a6d3a4f90d397792f2abd3bda0b60bc7" 
  )
  const ALGOLIA_INDEX = "netlify_0dcbce71-2b3a-4df0-a9f2-fe286f326fa6_master_all"
  const searchIndex = searchClient.initIndex(ALGOLIA_INDEX)
  const params = new URLSearchParams(location.search)
  const searchQuery = params.get("searchQuery")

  // Search Change Handler 
  const onSearchStateChange = updatedSearchState => {
    clearTimeout(debouncedSetState);
    setDebouncedSetState(
      setTimeout(() => {
        if (history !== undefined) {
          history.pushState(
            updatedSearchState,
            null,
            searchStateToUrl(updatedSearchState)
          );
        }
      }, DEBOUNCE_TIME)
    )
    setSearchState(updatedSearchState);
  }

  useEffect(() => {
    let q = searchState.query || searchQuery
    searchIndex.search(q)
      .then(({ hits, nbHits, hitsPerPage }) => {
        setNumberOfRecords(nbHits);
        if (q !== undefined) {
          setSearchQueryDisplay(q);
        }
        if (searchState.page) {
          var pageSize = hits.length * (searchState.page);
          if (pageSize > numberOfRecords) {
            pageSize = numberOfRecords
          }
          setLastResultIndex(pageSize);
          setCurrentPage(searchState.page);
        }
      })
      .catch(err => {
        console.error(err);
      })
  },[searchState])

  return (
    <StandardPageLayout pagePath="/search">
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Search</title>
          <meta name="robots" content="noindex"/>
        </Helmet>
        <Breadcrumb pagePath={location.pathname} key="search_breadcrumb" titleOverride="Search" />
        <div className="dark-wrapper">
          <div className="container inner">
            <div className="row">
              <div className="col-lg-8">
                <InstantSearch searchClient={searchClient} indexName={ALGOLIA_INDEX} searchState={searchState}
                  onSearchStateChange={onSearchStateChange}
                  createURL={createURL}>

                  <CustomSearchBox defaultRefinement={searchQuery} />
                  {numberOfRecords !== 0 && searchQueryDisplay ? <div>
                    <h3>{numberOfRecords} search results for <em>{searchQueryDisplay}</em></h3>
                    <div className="search-results">
                      <div className="media-list">
                        <Hits hitComponent={SearchHit} />
                      </div>
                    </div>
                    <em>{lastResultIndex} of {numberOfRecords} results</em>
                    <CustomPagination pageNumber={currentPage} /></div>
                    : searchQueryDisplay ? <h3>no search results for <em>{searchQueryDisplay}</em></h3> : ""}
                </InstantSearch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StandardPageLayout>
  )
}

export default SearchPage