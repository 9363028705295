import React from "react"
import { Snippet } from "react-instantsearch-dom"

import StoryblokLink from "./StoryblokLink";

function SearchHit({ hit }) {
  return (
    <div className="media">
      <div className="media-body search-item">
        <h3><StoryblokLink storyblokLinkData={{cached_url: hit.url}}>{hit.title}</StoryblokLink></h3>
        <p>
          <Snippet
            attribute="content"
            hit={hit} />
        </p>
        <StoryblokLink storyblokLinkData={{cached_url: hit.url}}>{"https://" + window.location.hostname + hit.url}</StoryblokLink>
      </div>
    </div>
  );
}

export default SearchHit