import React, { useEffect, useRef } from "react"

export default function ScrollToTop() {
  const scrollBtn = useRef()
  function OnClickScroll() {
    window.scroll({top: 0, behavior: "smooth"})
  }
  function handleScroll() {
    let y = window.scrollY
    if(scrollBtn && scrollBtn.current) {
      if (y > 300) {
        scrollBtn.current.classList.remove('scroll-hidden')
      } else {
        scrollBtn.current.classList.add('scroll-hidden')
      }
    }
  }
  useEffect(()=>{
    handleScroll()
    document.addEventListener('scroll', e=>{
      handleScroll()
    })
  }, [])

  return (
    <span id="scrollUp" ref={scrollBtn} onClick={OnClickScroll} className="cursor-pointer" style={{position: "fixed", zIndex: 1001, display: "inline"}}><i className="icon-up-open"></i></span>
  )
}