import React from "react"

function SearchBox({ currentRefinement, refine }) {
  function handleSubmit(e) {
      e.preventDefault();
  }
  return (
  <form onSubmit={handleSubmit} noValidate action="" role="search">
      <div className="search-box">
          <input
              type="search"
              value={currentRefinement}
              onChange={event => refine(event.currentTarget.value)}
              placeholder="Search"
          />
      </div>
  </form>
  )
}

export default SearchBox